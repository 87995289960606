import * as S from "./styles";

const HeroSection = ({ calcType }) => (
  <S.Wrapper>
    <S.Title>
      {!calcType && (
        <>
          Escolha o <span>assunto</span> abaixo e comece a <span>aprender</span>
        </>
      )}
      {calcType === "porcentagem" && (
        <>
          Aprenda como calcular <span>porcentagem</span>
        </>
      )}
      {calcType === "potencia" && (
        <>
          Aprenda como calcular <span>potência</span>
        </>
      )}
      {calcType === "area-do-circulo" && (
        <>
          Aprenda como calcular a <span>área de um círculo</span>
        </>
      )}
    </S.Title>
  </S.Wrapper>
);

export default HeroSection;
