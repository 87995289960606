import styled from "styled-components";

export const ButtonWrapper = styled.button`
  border: none;
  padding: 12px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  border-radius: 30px;
  transition: all 0.3s ease;

  &:hover {
    color: #fff;
    background: #f6ae14;
  }

  &:disabled {
    cursor: not-allowed;
    background: #ccc; /* Cor de fundo para botão desabilitado */
    color: #666; /* Cor do texto para botão desabilitado */
    opacity: 0.6; /* Transparência para botão desabilitado */
  }
`;
