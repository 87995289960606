import * as S from "./styles";

const Logo = () => (
  <S.Wrapper>
    <S.LogoText>MathQuest</S.LogoText>
    <S.Slogan>Simplificando a matematica</S.Slogan>
  </S.Wrapper>
);

export default Logo;
