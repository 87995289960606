import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  flex: 1;

  min-height: 364px;
  width: 800px;
  max-width: 100%;
  padding: 30px;

  border-radius: 17px;
  background: #fff;

  box-shadow: 0px 30px 50px 0px rgba(222, 220, 240, 1);
`;
