export const FatorialIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
    >
      <circle cx="63.5" cy="63.5" r="60" stroke="#0A0B0B" stroke-width="3" />
      <path
        d="M57.72 64.12L70.56 85H64.38L54.36 68.68L44.82 85H38.76L51.54 64.12L38.7 43.18H44.82L54.9 59.56L64.5 43.18H70.62L57.72 64.12ZM84.9656 43.18L84.3056 73.18H79.7456L79.0856 43.18H84.9656ZM82.2656 85.36C81.2256 85.36 80.3456 85 79.6256 84.28C78.9056 83.56 78.5456 82.68 78.5456 81.64C78.5456 80.6 78.9056 79.72 79.6256 79C80.3456 78.28 81.2256 77.92 82.2656 77.92C83.2656 77.92 84.1056 78.28 84.7856 79C85.5056 79.72 85.8656 80.6 85.8656 81.64C85.8656 82.68 85.5056 83.56 84.7856 84.28C84.1056 85 83.2656 85.36 82.2656 85.36Z"
        fill="black"
      />
    </svg>
  );
};
