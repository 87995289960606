import styled from "styled-components";

export const Wrapper = styled.main`
  display: flex;
  gap: 30px;

  flex: 1;

  margin-top: 10px;
`;

export const CardIcon = styled.div`
  width: 80px;
  height: 80px;

  padding: 10px;
  background: #f6ae14;
  border-radius: 50%;

  svg {
    width: 60px;
    height: 60px;
  }
`;

export const CardText = styled.div`
  height: 120px;
`;

export const CardTitle = styled.h4`
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const CardDescription = styled.p`
  letter-spacing: 0.7px;
`;

export const CalculatorInfo = styled.h4`
  font-weight: 600;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  grid-area: text;
`;

export const InputsWrapper = styled.div`
  display: flex;
  gap: 20px;

  & label {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  & input {
    width: 250px;
    height: 40px;
    padding: 10px;

    background-color: #eee;

    border: none;
    border-radius: 8px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const CalculationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ResultText = styled.p`
  grid-area: text;
  font-size: 30px;
`;

export const CalculatorHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-areas: "back text next";

  align-items: center;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const StepDescription = styled.p`
  text-align: center;
`;

export const StepAction = styled.p`
  font-size: 30px;
  font-weight: 700;
`;
