export const CircleArea = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      viewBox="0 0 128 128"
      data-name="Layer 1"
    >
      <path d="m20.07648 22.22546a1.99418 1.99418 0 0 0 1.41693-.57239q.69214-.69507 1.40723-1.3667a2.00006 2.00006 0 0 0 -2.73926-2.915q-.76318.717-1.502 1.45941a2.00512 2.00512 0 0 0 1.4171 3.39468z" />
      <path d="m11.27014 31.31079a2.03054 2.03054 0 0 0 2.7735-.55566q.54419-.81738 1.11621-1.61572a2.00025 2.00025 0 0 0 -3.254-2.32715q-.60937.85254-1.19141 1.7251a2.00021 2.00021 0 0 0 .5557 2.77343z" />
      <path d="m29.45007 14.50238c1.01917.0072 1.93866-.88422 2.7583-1.39764a2 2 0 1 0 -2.12207-3.39063q-.887.55444-1.754 1.13818a1.99246 1.99246 0 0 0 1.11774 3.65009z" />
      <path d="m5.16022 75.80493q-.19116-.95947-.35205-1.92969a1.99987 1.99987 0 0 0 -3.94635.65137q.17065 1.03638.37549 2.06055a2.03371 2.03371 0 0 0 2.3526 1.57031 2.00041 2.00041 0 0 0 1.57031-2.35254z" />
      <path d="m7.86139 85.22437a1.99994 1.99994 0 0 0 -3.74121 1.415q.36987.97778.771 1.94043a2.023 2.023 0 0 0 2.61523 1.0772 1.9996 1.9996 0 0 0 1.07721-2.61523q-.37573-.90163-.72223-1.8174z" />
      <path d="m2.77252 53.97583a2.01839 2.01839 0 0 0 2.34766-1.57764q.189-.96606.40869-1.92139a1.9999 1.9999 0 0 0 -3.8985-.89453q-.23364 1.01807-.43555 2.04639a1.99919 1.99919 0 0 0 1.5777 2.34717z" />
      <path d="m40.1394 8.73981a8.8056 8.8056 0 0 0 2.58667-.86017 2 2 0 0 0 -1.418-3.74023q-.97778.37061-1.93951.77246a2.00056 2.00056 0 0 0 -1.0752 2.61621 1.96889 1.96889 0 0 0 1.84604 1.21173z" />
      <path d="m4.00006 64.1062s.00879-1.34863.02783-1.9668a2 2 0 0 0 -3.99811-.12207c-.02001.65821-.02978 2.08887-.02978 2.08887a2 2 0 0 0 4.00006 0z" />
      <path d="m8.28918 36.6853a2.00083 2.00083 0 0 0 -2.64606.99951q-.4314.95581-.83252 1.92725a2.00027 2.00027 0 0 0 3.6983 1.52539q.375-.9104.77979-1.80615a2.00021 2.00021 0 0 0 -.99951-2.646z" />
      <path d="m97.20081 14.01489q.81885.54419 1.61823 1.11523a2.02612 2.02612 0 0 0 2.79-.46631 2.00034 2.00034 0 0 0 -.46582-2.78955q-.85254-.60864-1.72559-1.18945a2.00024 2.00024 0 0 0 -2.21686 3.33008z" />
      <path d="m51.74963 5.21021a14.79573 14.79573 0 0 0 2.3252-.39355 1.99984 1.99984 0 1 0 -.65723-3.94532q-1.03491.17212-2.05859.37744a2.0002 2.0002 0 0 0 .39063 3.96143z" />
      <path d="m107.67749 22.86255a2.00044 2.00044 0 0 0 2.91608-2.73926q-.71777-.76392-1.462-1.50244a2.00014 2.00014 0 1 0 -2.82031 2.83691q.69582.69068 1.36623 1.40479z" />
      <path d="m122.82025 52.09351q.19336.96167.35742 1.93457a2.02978 2.02978 0 0 0 2.30573 1.6377 1.99982 1.99982 0 0 0 1.6377-2.30566q-.17432-1.03271-.37891-2.05469a2.00017 2.00017 0 0 0 -3.92194.78809z" />
      <path d="m120.10345 42.67993a2.02513 2.02513 0 0 0 2.58105 1.15723 2.00067 2.00067 0 0 0 1.15723-2.58105q-.37207-.97632-.77441-1.938a1.99986 1.99986 0 0 0 -3.68951 1.54443q.37646.90162.72564 1.81739z" />
      <path d="m63.84009 4.00024s2.00879.02734 2.0293.02734a2 2 0 0 0 .05861-3.99902c-.63672-.019-2.08789-.02832-2.08789-.02832a2 2 0 0 0 0 4z" />
      <path d="m75.55017 5.11011a13.071 13.071 0 0 0 2.3692.4585 2.00023 2.00023 0 0 0 .44531-3.94971q-1.01807-.23364-2.04694-.43457a2.00006 2.00006 0 0 0 -.76758 3.92578z" />
      <path d="m86.81592 8.4895a7.61753 7.61753 0 0 0 2.627.95557 2.00035 2.00035 0 0 0 .82324-3.82373q-.95654-.4314-1.92871-.83105a2 2 0 1 0 -1.52153 3.69921z" />
      <path d="m97.35022 113.885q-.813.54492-1.64648 1.064a2.00113 2.00113 0 0 0 -.63867 2.75537 2.0385 2.0385 0 0 0 2.75586.63916q.8877-.55371 1.75592-1.13525a2.00011 2.00011 0 1 0 -2.22662-3.32324z" />
      <path d="m106.43433 106.41919q-.69287.6936-1.4082 1.36377a2.00008 2.00008 0 0 0 -.09375 2.82715 2.02157 2.02157 0 0 0 2.82617.09375q.76611-.717 1.50586-1.45752a2.00013 2.00013 0 0 0 -2.83008-2.82715z" />
      <path d="m116.67468 96.78a1.99945 1.99945 0 0 0 -2.77448.54834q-.54785.81665-1.12109 1.61572a2.00044 2.00044 0 0 0 .459 2.791 2.02334 2.02334 0 0 0 2.79108-.459q.61084-.85107 1.19336-1.72119a1.99949 1.99949 0 0 0 -.54787-2.77487z" />
      <path d="m13.07776 95.74829a1.99992 1.99992 0 1 0 -3.39356 2.11719q.55444.88916 1.13721 1.75635a2.02533 2.02533 0 0 0 2.77545.5459 2.00022 2.00022 0 0 0 .5459-2.77539q-.54565-.81226-1.065-1.64405z" />
      <path d="m86.99463 119.43628q-.90234.375-1.81934.7207a2.00036 2.00036 0 0 0 -1.16406 2.57813 2.04351 2.04351 0 0 0 2.57813 1.16406q.97852-.36987 1.94049-.76953a1.99986 1.99986 0 1 0 -1.53522-3.69336z" />
      <path d="m125.21088 74.12817a2.00361 2.00361 0 0 0 -2.35059 1.57324q-.19043.9646-.41211 1.91748a1.99965 1.99965 0 1 0 3.89459.90918c.15918-.6792.30469-1.3623.44141-2.04932a1.99992 1.99992 0 0 0 -1.5733-2.35058z" />
      <path d="m126 61.78687a2.00011 2.00011 0 0 0 -2.00006 2s-.00977 1.5249-.03027 2.17676a2.00018 2.00018 0 0 0 1.93561 2.063 1.99736 1.99736 0 0 0 2.0625-1.93506c.02246-.69482.03223-2.30469.03223-2.30469a2.0001 2.0001 0 0 0 -2.00001-2.00001z" />
      <path d="m122.06537 85.87573a1.99794 1.99794 0 0 0 -2.61334 1.082q-.37646.90967-.78223 1.80322a1.9998 1.9998 0 0 0 .99316 2.64844 2.02 2.02 0 0 0 2.64752-.99316q.43506-.95581.83691-1.92725a1.99991 1.99991 0 0 0 -1.08202-2.61325z" />
      <path d="m75.75427 122.84937q-.95947.19043-1.93164.3501a2.00038 2.00038 0 0 0 .32324 3.97412 12.78275 12.78275 0 0 0 2.3877-.40137 1.99975 1.99975 0 1 0 -.7793-3.92285z" />
      <path d="m118.25769 30.03931q-.55664-.887-1.13965-1.75244a2.00027 2.00027 0 0 0 -3.31842 2.23438q.54785.813 1.0694 1.644a2.02182 2.02182 0 0 0 2.75781.63135 2.00048 2.00048 0 0 0 .63086-2.75729z" />
      <path d="m20.25122 105.06079a2.00013 2.00013 0 0 0 -2.91705 2.7373q.717.76392 1.45807 1.50293a2.02841 2.02841 0 0 0 2.82813-.00293 1.99927 1.99927 0 0 0 -.00293-2.82812q-.69434-.6936-1.36622-1.40918z" />
      <path d="m64.00024 123.99976q-.95947 0-1.91162-.02979a2 2 0 1 0 -.125 3.998c.67578.021 2.08984.03174 2.08984.03174a1.977 1.977 0 0 0 1.97314-2 2.02278 2.02278 0 0 0 -2.02636-1.99995z" />
      <path d="m30.71277 113.92749q-.81738-.54565-1.6153-1.11768a1.99988 1.99988 0 1 0 -2.33008 3.251q.85181.61084 1.72363 1.19287a2.02325 2.02325 0 0 0 2.774-.55225 1.99972 1.99972 0 0 0 -.55225-2.77394z" />
      <path d="m52.34778 122.86938q-.96533-.1897-1.91949-.41064a2.00006 2.00006 0 0 0 -.90039 3.89746q1.01733.23511 2.04742.438a2.022 2.022 0 0 0 2.34868-1.5762 2.00017 2.00017 0 0 0 -1.57622-2.34862z" />
      <path d="m41.08984 119.47144q-.9104-.37646-1.80621-.78174a1.9998 1.9998 0 1 0 -1.64746 3.64453q.95435.43213 1.92529.8335a1.99991 1.99991 0 1 0 1.52838-3.69629z" />
      <path d="m64.00024 112.99976a49 49 0 1 0 -49-49 49.05567 49.05567 0 0 0 49 49zm0-94a45 45 0 1 1 -45 45 45.05077 45.05077 0 0 1 45-45z" />
      <path d="m33.89087 65.66382 6 4a2 2 0 0 0 2.21875-3.32812l-.50391-.33594h44.78907l-.50391.33594a2 2 0 0 0 2.21875 3.32813l6-4a1.9999 1.9999 0 0 0 0-3.32812l-6-4a2 2 0 1 0 -2.21875 3.32813l.50391.33594h-44.78907l.50391-.33594a2 2 0 0 0 -2.21875-3.32812l-6 4a1.9999 1.9999 0 0 0 0 3.32812z" />
    </svg>
  );
};
