import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 364px;
  width: 300px;
  padding: 30px;

  border-radius: 17px;
  background: #fff;

  box-shadow: 0px 30px 50px 0px rgba(222, 220, 240, 1);
`;
