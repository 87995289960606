import Logo from "../Logo";
import * as S from "./styles";

const Header = () => (
  <S.Wrapper>
    <Logo />
  </S.Wrapper>
);

export default Header;
