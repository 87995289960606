import * as S from "./styles";

const Footer = () => (
  <S.Wrapper>
    Copyright © 2024. Todos os Direitos Reservados. MOREIRA, Nicolas Dominiak e
    CORDEIRO, Ana Júlia
  </S.Wrapper>
);

export default Footer;
