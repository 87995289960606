import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 10px 40px;
`;

export const Title = styled.h2`
  font-size: 44px;
  color: #072422;
  text-align: center;
  max-width: 750px;

  & span {
    color: #f6ae14;
  }
`;

export const Description = styled.p``;
