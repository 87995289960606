import { useState } from "react";
import { GlobalStyle } from "./GlobalStyle";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Main from "./components/Main";
import Footer from "./components/Footer";

export default function App() {
  const [calcType, setCalcType] = useState(null);

  return (
    <>
      <GlobalStyle />
      <Header />
      <HeroSection calcType={calcType} />
      <Main calcType={calcType} onSelectType={setCalcType} />
      <Footer />
    </>
  );
}
