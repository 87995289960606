import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  padding: 20px;
  width: 100%;

  font-size: 14px;

  display: flex;
  justify-content: center;
`;
