export const PowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
    >
      <g clip-path="url(#clip0_66_2)">
        <path
          d="M20.0765 22.2259C20.3387 22.2284 20.5988 22.1791 20.8419 22.0808C21.0851 21.9826 21.3064 21.8374 21.4934 21.6535C21.9548 21.1902 22.4239 20.7346 22.9006 20.2868C23.2851 19.9231 23.5096 19.4218 23.5252 18.8927C23.5407 18.3637 23.3459 17.8501 22.9835 17.4644C22.6211 17.0787 22.1205 16.8524 21.5916 16.8351C21.0626 16.8177 20.5483 17.0107 20.1614 17.3718C19.6526 17.8498 19.1519 18.3363 18.6594 18.8312C18.3885 19.113 18.2058 19.4677 18.1339 19.8518C18.0619 20.236 18.1038 20.6328 18.2544 20.9934C18.4049 21.3541 18.6576 21.6629 18.9813 21.8819C19.305 22.1008 19.6857 22.2204 20.0765 22.2259Z"
          fill="black"
        />
        <path
          d="M11.2702 31.3108C11.7139 31.597 12.2518 31.6991 12.7696 31.5953C13.2874 31.4916 13.7444 31.1902 14.0437 30.7551C14.4064 30.2102 14.7785 29.6716 15.1599 29.1394C15.3127 28.9258 15.4219 28.6841 15.4813 28.4282C15.5407 28.1724 15.5491 27.9073 15.5061 27.6482C15.4631 27.389 15.3694 27.1409 15.2305 26.918C15.0916 26.695 14.9101 26.5016 14.6964 26.3488C14.4828 26.196 14.2411 26.0868 13.9852 26.0274C13.7294 25.968 13.4643 25.9596 13.2052 26.0026C12.946 26.0456 12.6979 26.1393 12.475 26.2782C12.2521 26.4171 12.0587 26.5986 11.9059 26.8123C11.4996 27.3806 11.1025 27.9557 10.7145 28.5374C10.4204 28.9788 10.3137 29.5191 10.418 30.0392C10.5222 30.5593 10.8287 31.0167 11.2702 31.3108Z"
          fill="black"
        />
        <path
          d="M29.4501 14.5029C30.4693 14.5101 31.3887 13.6186 32.2084 13.1052C32.4325 12.9666 32.627 12.785 32.7808 12.571C32.9345 12.3569 33.0444 12.1146 33.1042 11.858C33.164 11.6013 33.1724 11.3353 33.1291 11.0754C33.0857 10.8155 32.9915 10.5666 32.8517 10.3432C32.7118 10.1199 32.5293 9.9263 32.3144 9.7737C32.0995 9.62111 31.8566 9.51248 31.5997 9.45406C31.3427 9.39564 31.0767 9.38858 30.817 9.43329C30.5573 9.478 30.309 9.5736 30.0863 9.71459C29.495 10.0842 28.9103 10.4636 28.3323 10.8528C27.9757 11.0906 27.7053 11.4371 27.5612 11.8408C27.4171 12.2445 27.4071 12.684 27.5326 13.0938C27.6581 13.5037 27.9125 13.8622 28.2579 14.116C28.6033 14.3698 29.0214 14.5055 29.4501 14.5029Z"
          fill="black"
        />
        <path
          d="M5.16025 75.8051C5.03281 75.1654 4.91546 74.5222 4.8082 73.8754C4.72182 73.352 4.4311 72.8845 3.99998 72.5755C3.56886 72.2665 3.03266 72.1415 2.50934 72.2279C1.98602 72.3143 1.51845 72.605 1.20949 73.0361C0.900521 73.4672 0.775473 74.0034 0.86185 74.5267C0.975617 75.2177 1.10078 75.9045 1.23734 76.5873C1.34904 77.1032 1.65693 77.5554 2.09594 77.8484C2.53495 78.1414 3.07069 78.2523 3.58994 78.1576C4.11009 78.0538 4.56772 77.7476 4.86219 77.3065C5.15667 76.8653 5.26388 76.3252 5.16025 75.8051Z"
          fill="black"
        />
        <path
          d="M7.86139 85.224C7.67256 84.7295 7.29541 84.33 6.81258 84.1131C6.32975 83.8962 5.78062 83.8796 5.28554 84.0668C4.79046 84.2541 4.38979 84.63 4.17135 85.1121C3.95291 85.5942 3.93451 86.1433 4.12018 86.639C4.36676 87.2908 4.62376 87.9376 4.89118 88.5794C5.09995 89.065 5.4905 89.4497 5.97926 89.651C6.46803 89.8523 7.01618 89.8543 7.50641 89.6566C7.74889 89.5557 7.96911 89.4079 8.15448 89.2218C8.33986 89.0358 8.48676 88.815 8.58679 88.5721C8.68683 88.3293 8.73803 88.0691 8.73749 87.8064C8.73695 87.5438 8.68466 87.2838 8.58362 87.0414C8.33314 86.4403 8.09239 85.8345 7.86139 85.224Z"
          fill="black"
        />
        <path
          d="M2.77251 53.9755C3.29251 54.0726 3.82985 53.9617 4.26891 53.6667C4.70797 53.3716 5.01365 52.916 5.12017 52.3979C5.24617 51.7538 5.3824 51.1134 5.52886 50.4765C5.5876 50.2205 5.59534 49.9554 5.55164 49.6965C5.50795 49.4375 5.41368 49.1897 5.2742 48.9671C5.13473 48.7446 4.95279 48.5517 4.73878 48.3995C4.52476 48.2473 4.28286 48.1387 4.02688 48.08C3.7709 48.0212 3.50586 48.0135 3.24688 48.0572C2.98791 48.1009 2.74008 48.1951 2.51755 48.3346C2.29501 48.4741 2.10212 48.656 1.9499 48.87C1.79768 49.0841 1.6891 49.326 1.63036 49.5819C1.4746 50.2607 1.32942 50.9428 1.19481 51.6283C1.14421 51.886 1.14488 52.1512 1.19678 52.4087C1.24869 52.6661 1.35082 52.9108 1.49734 53.1288C1.64385 53.3468 1.83188 53.5337 2.05068 53.679C2.26948 53.8243 2.51476 53.925 2.77251 53.9755Z"
          fill="black"
        />
        <path
          d="M40.1394 8.74005C41.0411 8.58934 41.9137 8.29914 42.7261 7.87988C43.2221 7.69185 43.623 7.31448 43.8408 6.8308C44.0586 6.34712 44.0752 5.79675 43.8872 5.30077C43.6992 4.80478 43.3218 4.40381 42.8381 4.18606C42.3544 3.96831 41.8041 3.95162 41.3081 4.13965C40.6562 4.38673 40.0097 4.64421 39.3686 4.91211C38.8792 5.11657 38.491 5.50702 38.2893 5.99761C38.0877 6.48821 38.0892 7.0388 38.2934 7.52832C38.4447 7.89169 38.7015 8.20135 39.0305 8.41735C39.3596 8.63334 39.7458 8.74575 40.1394 8.74005Z"
          fill="black"
        />
        <path
          d="M4.00006 64.1064C4.00006 64.1064 4.00885 62.7578 4.02789 62.1396C4.0421 61.6107 3.8461 61.0976 3.48281 60.7129C3.11952 60.3281 2.61856 60.103 2.08965 60.0869C1.56073 60.0707 1.04697 60.2648 0.660889 60.6267C0.274806 60.9886 0.0478691 61.4887 0.02978 62.0176C0.00977 62.6758 0 64.1064 0 64.1064C0 64.6369 0.210717 65.1456 0.585795 65.5207C0.960874 65.8957 1.46959 66.1065 2.00003 66.1065C2.53047 66.1065 3.03919 65.8957 3.41426 65.5207C3.78934 65.1456 4.00006 64.6369 4.00006 64.1064Z"
          fill="black"
        />
        <path
          d="M8.28916 36.6849C7.8057 36.4667 7.25541 36.4495 6.75923 36.6369C6.26306 36.8243 5.8616 37.2011 5.6431 37.6844C5.3555 38.3216 5.07799 38.964 4.81058 39.6116C4.60971 40.1018 4.61148 40.6516 4.81548 41.1405C5.01948 41.6293 5.40907 42.0173 5.89877 42.2193C6.38847 42.4213 6.93828 42.4207 7.42761 42.2178C7.91693 42.015 8.30579 41.6263 8.50888 41.137C8.75888 40.5301 9.01881 39.928 9.28867 39.3309C9.50696 38.8474 9.52429 38.2971 9.33685 37.8009C9.14941 37.3047 8.77255 36.9032 8.28916 36.6849Z"
          fill="black"
        />
        <path
          d="M97.2008 14.0149C97.7467 14.3777 98.2861 14.7494 98.8191 15.1301C99.2531 15.4316 99.7877 15.5516 100.309 15.4644C100.83 15.3773 101.297 15.09 101.609 14.6638C101.917 14.2321 102.041 13.6957 101.954 13.1726C101.866 12.6495 101.575 12.1825 101.143 11.8743C100.575 11.4685 99.9997 11.072 99.4176 10.6848C98.976 10.3908 98.4358 10.2843 97.9156 10.3887C97.3955 10.4931 96.9381 10.7998 96.6442 11.2414C96.3502 11.683 96.2437 12.2233 96.3481 12.7434C96.4525 13.2636 96.7592 13.7209 97.2008 14.0149Z"
          fill="black"
        />
        <path
          d="M51.7496 5.2098C52.5339 5.1407 53.3115 5.0091 54.0748 4.81625C54.3339 4.7731 54.5819 4.67934 54.8047 4.54034C55.0275 4.40133 55.2208 4.2198 55.3735 4.00611C55.5261 3.79242 55.6352 3.55075 55.6945 3.2949C55.7537 3.03905 55.762 2.77403 55.7189 2.51498C55.6757 2.25593 55.582 2.00791 55.443 1.78509C55.304 1.56227 55.1224 1.36901 54.9087 1.21635C54.695 1.06368 54.4534 0.954604 54.1975 0.895338C53.9417 0.836072 53.6767 0.82778 53.4176 0.870934C52.7277 0.985681 52.0415 1.11149 51.359 1.24837C50.8726 1.34583 50.4397 1.62058 50.1445 2.01928C49.8492 2.41798 49.7127 2.91217 49.7614 3.40589C49.8101 3.89961 50.0405 4.35761 50.4079 4.69097C50.7753 5.02433 51.2535 5.20924 51.7496 5.2098Z"
          fill="black"
        />
        <path
          d="M107.677 22.8625C108.041 23.2492 108.543 23.4758 109.073 23.4924C109.603 23.5089 110.118 23.3142 110.505 22.9509C110.892 22.5877 111.118 22.0857 111.135 21.5554C111.152 21.0251 110.957 20.51 110.594 20.1233C110.115 19.614 109.628 19.1132 109.132 18.6208C108.945 18.4352 108.724 18.2881 108.481 18.1879C108.238 18.0878 107.978 18.0365 107.715 18.0371C107.452 18.0377 107.192 18.0901 106.949 18.1913C106.707 18.2926 106.486 18.4406 106.301 18.6271C106.116 18.8135 105.969 19.0347 105.869 19.2779C105.769 19.5211 105.718 19.7817 105.719 20.0446C105.72 20.3075 105.773 20.5676 105.875 20.8101C105.976 21.0526 106.125 21.2726 106.311 21.4577C106.775 21.9182 107.231 22.3865 107.677 22.8625Z"
          fill="black"
        />
        <path
          d="M122.82 52.0931C122.949 52.7343 123.068 53.3791 123.178 54.0277C123.273 54.547 123.568 55.0085 123.998 55.3142C124.429 55.62 124.962 55.746 125.483 55.6654C126.006 55.5768 126.473 55.2841 126.78 54.8517C127.087 54.4194 127.21 53.8827 127.121 53.3598C127.005 52.6713 126.879 51.9864 126.742 51.3051C126.637 50.7855 126.33 50.3289 125.889 50.0355C125.447 49.7421 124.907 49.6359 124.388 49.7404C123.868 49.8448 123.411 50.1513 123.117 50.5924C122.823 51.0336 122.716 51.5733 122.82 52.0931Z"
          fill="black"
        />
        <path
          d="M120.103 42.6797C120.298 43.1712 120.676 43.5674 121.158 43.7836C121.641 43.9998 122.188 44.0189 122.685 43.8369C123.18 43.648 123.58 43.2699 123.797 42.7859C124.014 42.3019 124.03 41.7516 123.842 41.2558C123.594 40.605 123.336 39.959 123.067 39.3178C122.862 38.829 122.471 38.4417 121.981 38.2408C121.49 38.04 120.94 38.0422 120.451 38.2469C119.962 38.4516 119.574 38.842 119.373 39.3325C119.172 39.8229 119.174 40.3731 119.378 40.8623C119.629 41.4634 119.871 42.0692 120.103 42.6797Z"
          fill="black"
        />
        <path
          d="M63.8401 4C63.8401 4 65.8489 4.02734 65.8694 4.02734C66.3941 4.02632 66.8973 3.81916 67.2707 3.45052C67.644 3.08188 67.8576 2.58128 67.8653 2.05665C67.873 1.53202 67.6742 1.02538 67.3118 0.645957C66.9494 0.266531 66.4524 0.0447118 65.928 0.02832C65.2913 0.00932 63.8401 0 63.8401 0C63.3097 0 62.8009 0.210714 62.4259 0.585786C62.0508 0.960859 61.8401 1.46957 61.8401 2C61.8401 2.53043 62.0508 3.03914 62.4259 3.41421C62.8009 3.78929 63.3096 4 63.8401 4Z"
          fill="black"
        />
        <path
          d="M75.5501 5.10987C76.3241 5.33544 77.1172 5.48892 77.9193 5.56837C78.4106 5.56778 78.8845 5.38642 79.2506 5.05885C79.6168 4.73128 79.8495 4.28043 79.9046 3.79224C79.9596 3.30406 79.8331 2.81269 79.5491 2.41182C79.2651 2.01095 78.8435 1.72863 78.3647 1.61866C77.6859 1.4629 77.0036 1.31805 76.3177 1.18409C75.7975 1.08314 75.2584 1.19279 74.8189 1.48897C74.3795 1.78514 74.0755 2.24363 73.9738 2.76374C73.8721 3.28385 73.981 3.82305 74.2766 4.26294C74.5721 4.70283 75.0302 5.00744 75.5501 5.10987Z"
          fill="black"
        />
        <path
          d="M86.8159 8.48972C87.6251 8.96757 88.5158 9.29154 89.4429 9.44529C89.9004 9.44544 90.3441 9.28875 90.7001 9.00135C91.0561 8.71396 91.3028 8.31322 91.3991 7.86597C91.4954 7.41871 91.4354 6.95196 91.2293 6.54354C91.0231 6.13511 90.6832 5.8097 90.2661 5.62156C89.6285 5.33396 88.9855 5.05695 88.3374 4.79051C88.0942 4.68844 87.8331 4.63551 87.5693 4.63477C87.3054 4.63404 87.0441 4.6855 86.8002 4.78621C86.5564 4.88692 86.3348 5.03488 86.1484 5.22157C85.962 5.40826 85.8144 5.62999 85.714 5.87398C85.6136 6.11798 85.5626 6.37942 85.5637 6.64324C85.5648 6.90707 85.6181 7.16806 85.7205 7.4112C85.8229 7.65433 85.9725 7.8748 86.1605 8.0599C86.3485 8.24501 86.5712 8.39108 86.8159 8.48972Z"
          fill="black"
        />
        <path
          d="M97.3502 113.885C96.8082 114.249 96.2594 114.603 95.7037 114.949C95.2539 115.23 94.934 115.678 94.8142 116.195C94.6945 116.711 94.7847 117.255 95.0651 117.705C95.3524 118.147 95.8001 118.461 96.3141 118.58C96.828 118.699 97.3681 118.615 97.8209 118.344C98.4127 117.975 98.998 117.596 99.5768 117.209C99.7951 117.062 99.9823 116.875 100.128 116.656C100.274 116.438 100.375 116.192 100.426 115.935C100.477 115.677 100.476 115.412 100.425 115.154C100.373 114.897 100.271 114.652 100.125 114.434C99.979 114.215 99.7912 114.028 99.5726 113.883C99.354 113.737 99.1089 113.636 98.8513 113.585C98.5936 113.534 98.3284 113.534 98.0708 113.586C97.8133 113.637 97.5684 113.739 97.3502 113.885Z"
          fill="black"
        />
        <path
          d="M106.434 106.42C105.972 106.882 105.503 107.337 105.026 107.783C104.834 107.963 104.68 108.178 104.571 108.418C104.463 108.657 104.402 108.915 104.394 109.177C104.385 109.44 104.428 109.702 104.52 109.948C104.613 110.193 104.753 110.419 104.932 110.61C105.298 110.992 105.798 111.216 106.326 111.234C106.855 111.251 107.369 111.061 107.759 110.704C108.269 110.226 108.771 109.74 109.264 109.247C109.639 108.871 109.85 108.363 109.849 107.832C109.849 107.302 109.638 106.793 109.263 106.418C108.888 106.043 108.379 105.833 107.848 105.833C107.318 105.833 106.809 106.044 106.434 106.42Z"
          fill="black"
        />
        <path
          d="M116.675 96.7799C116.457 96.6337 116.212 96.5319 115.954 96.4803C115.697 96.4288 115.432 96.4284 115.174 96.4793C114.916 96.5303 114.671 96.6314 114.453 96.7771C114.234 96.9228 114.046 97.1101 113.9 97.3283C113.535 97.8727 113.161 98.4113 112.779 98.944C112.47 99.375 112.345 99.9111 112.431 100.435C112.517 100.958 112.807 101.426 113.238 101.735C113.671 102.038 114.206 102.16 114.727 102.074C115.249 101.988 115.716 101.702 116.029 101.276C116.436 100.709 116.834 100.135 117.223 99.5548C117.369 99.3367 117.471 99.0918 117.522 98.8343C117.574 98.5767 117.574 98.3115 117.524 98.0539C117.473 97.7962 117.372 97.551 117.226 97.3324C117.08 97.1139 116.893 96.9261 116.675 96.7799Z"
          fill="black"
        />
        <path
          d="M13.0777 95.748C12.9387 95.5251 12.7572 95.3319 12.5435 95.1792C12.3298 95.0266 12.0881 94.9175 11.8322 94.8582C11.5764 94.799 11.3113 94.7907 11.0523 94.8338C10.7932 94.877 10.5452 94.9708 10.3224 95.1098C10.0995 95.2488 9.90628 95.4303 9.75362 95.644C9.60096 95.8578 9.49188 96.0994 9.43262 96.3553C9.37336 96.6112 9.36507 96.8762 9.40824 97.1352C9.4514 97.3943 9.54517 97.6423 9.68418 97.8652C10.0538 98.4579 10.4329 99.0434 10.8214 99.6215C11.1213 100.057 11.5795 100.357 12.098 100.459C12.6166 100.561 13.1545 100.457 13.5968 100.167C14.0372 99.8717 14.3422 99.4132 14.4445 98.8928C14.5469 98.3723 14.4384 97.8325 14.1427 97.392C13.779 96.8505 13.424 96.3025 13.0777 95.748Z"
          fill="black"
        />
        <path
          d="M86.9946 119.436C86.3931 119.686 85.7866 119.927 85.1753 120.157C84.6791 120.345 84.2777 120.722 84.0594 121.205C83.8411 121.689 83.8238 122.239 84.0112 122.735C84.2079 123.224 84.5859 123.619 85.0663 123.835C85.5468 124.052 86.0925 124.075 86.5894 123.899C87.2417 123.653 87.8885 123.396 88.5298 123.13C88.7724 123.029 88.9926 122.881 89.1781 122.695C89.3636 122.509 89.5106 122.289 89.6108 122.046C89.711 121.803 89.7624 121.543 89.7621 121.281C89.7618 121.018 89.7097 120.758 89.6089 120.515C89.5081 120.273 89.3605 120.053 89.1746 119.867C88.9887 119.682 88.768 119.535 88.5253 119.434C88.2825 119.334 88.0224 119.283 87.7598 119.283C87.4971 119.284 87.2371 119.336 86.9946 119.436Z"
          fill="black"
        />
        <path
          d="M125.211 74.1283C124.691 74.0262 124.151 74.1345 123.711 74.4294C123.27 74.7242 122.964 75.1817 122.86 75.7016C122.733 76.3446 122.596 76.9838 122.448 77.6191C122.388 77.8748 122.38 78.1398 122.422 78.3989C122.465 78.658 122.558 78.9061 122.697 79.1292C122.836 79.3522 123.017 79.5457 123.23 79.6988C123.444 79.8518 123.685 79.9612 123.941 80.0209C124.197 80.0806 124.462 80.0894 124.721 80.0467C124.98 80.004 125.228 79.9106 125.451 79.772C125.674 79.6334 125.868 79.4522 126.021 79.2388C126.174 79.0254 126.283 78.784 126.343 78.5282C126.502 77.849 126.647 77.1659 126.784 76.4789C126.835 76.2213 126.835 75.9561 126.784 75.6985C126.732 75.4409 126.63 75.196 126.484 74.9778C126.338 74.7595 126.151 74.5721 125.932 74.4264C125.714 74.2806 125.469 74.1794 125.211 74.1283Z"
          fill="black"
        />
        <path
          d="M126 61.7871C125.47 61.7871 124.961 61.9978 124.586 62.3729C124.211 62.748 124 63.2567 124 63.7871C124 63.7871 123.99 65.312 123.97 65.9639C123.961 66.2264 124.005 66.4881 124.097 66.7338C124.19 66.9796 124.33 67.2047 124.51 67.3963C124.69 67.5879 124.906 67.7421 125.145 67.8503C125.384 67.9585 125.643 68.0185 125.905 68.0269C126.168 68.0356 126.43 67.9924 126.675 67.8998C126.921 67.8072 127.146 67.667 127.338 67.4872C127.53 67.3075 127.684 67.0917 127.792 66.8523C127.9 66.6128 127.96 66.3544 127.968 66.0918C127.99 65.397 128 63.7871 128 63.7871C128 63.2567 127.789 62.748 127.414 62.3729C127.039 61.9979 126.53 61.7871 126 61.7871Z"
          fill="black"
        />
        <path
          d="M122.065 85.8757C121.823 85.7748 121.563 85.7228 121.3 85.7227C121.037 85.7225 120.777 85.7741 120.534 85.8746C120.292 85.9751 120.071 86.1225 119.885 86.3084C119.7 86.4942 119.552 86.7148 119.452 86.9577C119.201 87.5641 118.94 88.1652 118.67 88.7609C118.561 89 118.501 89.2582 118.492 89.5207C118.483 89.7832 118.526 90.0449 118.618 90.2908C118.71 90.5368 118.85 90.7621 119.029 90.954C119.208 91.146 119.424 91.3007 119.663 91.4093C120.146 91.6232 120.694 91.6389 121.189 91.4532C121.684 91.2676 122.087 90.8952 122.31 90.4162C122.6 89.779 122.879 89.1365 123.147 88.4889C123.248 88.2463 123.3 87.9862 123.3 87.7236C123.3 87.4609 123.248 87.2008 123.148 86.9582C123.047 86.7155 122.9 86.495 122.714 86.3093C122.528 86.1235 122.308 85.9762 122.065 85.8757Z"
          fill="black"
        />
        <path
          d="M75.7543 122.85C75.1147 122.977 74.4708 123.094 73.8227 123.2C73.3273 123.282 72.8807 123.547 72.5716 123.942C72.2625 124.338 72.1135 124.835 72.1542 125.336C72.1949 125.836 72.4223 126.303 72.7913 126.644C73.1603 126.984 73.6438 127.174 74.1459 127.174C74.9527 127.116 75.7522 126.982 76.5336 126.773C76.7912 126.722 77.0362 126.62 77.2545 126.474C77.4729 126.328 77.6605 126.141 77.8064 125.923C77.9524 125.704 78.0539 125.459 78.1052 125.202C78.1565 124.944 78.1565 124.679 78.1054 124.422C78.0542 124.164 77.9528 123.919 77.807 123.701C77.6611 123.482 77.4737 123.295 77.2554 123.149C77.037 123.003 76.7921 122.901 76.5346 122.85C76.277 122.799 76.0119 122.799 75.7543 122.85Z"
          fill="black"
        />
        <path
          d="M118.258 30.0389C117.887 29.4475 117.507 28.8634 117.118 28.2864C116.822 27.8464 116.363 27.542 115.842 27.4404C115.321 27.3387 114.782 27.4481 114.342 27.7444C113.902 28.0407 113.597 28.4997 113.496 29.0203C113.394 29.541 113.503 30.0807 113.8 30.5208C114.165 31.0628 114.521 31.6108 114.869 32.1648C115.155 32.6098 115.604 32.9252 116.119 33.0432C116.635 33.1612 117.176 33.0725 117.627 32.7961C118.076 32.5141 118.395 32.0652 118.513 31.5481C118.631 31.0311 118.54 30.4882 118.258 30.0389Z"
          fill="black"
        />
        <path
          d="M20.2512 105.061C19.8879 104.675 19.3865 104.45 18.8571 104.434C18.3277 104.417 17.8135 104.612 17.4273 104.974C17.041 105.337 16.8143 105.837 16.7969 106.367C16.7794 106.896 16.9726 107.411 17.3342 107.798C17.8122 108.307 18.2982 108.808 18.7922 109.301C19.1713 109.669 19.6788 109.874 20.2069 109.874C20.735 109.873 21.2421 109.667 21.6204 109.298C21.8059 109.112 21.9531 108.892 22.0534 108.649C22.1536 108.406 22.2051 108.146 22.2048 107.883C22.2046 107.621 22.1526 107.361 22.0518 107.118C21.951 106.876 21.8034 106.655 21.6174 106.47C21.1545 106.007 20.6991 105.538 20.2512 105.061Z"
          fill="black"
        />
        <path
          d="M64.0002 124C63.3606 124 62.7234 123.99 62.0886 123.97C61.8253 123.961 61.5628 124.003 61.316 124.095C61.0692 124.188 60.8431 124.328 60.6507 124.508C60.4582 124.688 60.3032 124.904 60.1945 125.144C60.0859 125.384 60.0257 125.643 60.0175 125.906C60.0092 126.17 60.0531 126.432 60.1466 126.678C60.24 126.925 60.3812 127.15 60.5621 127.342C60.7429 127.533 60.9598 127.687 61.2003 127.795C61.4409 127.902 61.7002 127.961 61.9636 127.968C62.6394 127.989 64.0534 128 64.0534 128C64.3147 127.999 64.5732 127.947 64.8142 127.846C65.0551 127.745 65.2736 127.597 65.4571 127.411C65.6406 127.225 65.7854 127.005 65.8831 126.763C65.9809 126.52 66.0296 126.261 66.0266 126C66.0206 125.467 65.8043 124.957 65.4248 124.583C65.0454 124.208 64.5334 123.999 64.0002 124Z"
          fill="black"
        />
        <path
          d="M30.7128 113.928C30.1679 113.564 29.6294 113.191 29.0975 112.81C28.6664 112.501 28.1302 112.376 27.6069 112.462C27.0835 112.549 26.6159 112.839 26.307 113.27C25.998 113.702 25.8729 114.238 25.9592 114.761C26.0456 115.284 26.3363 115.752 26.7674 116.061C27.3353 116.468 27.9098 116.866 28.491 117.254C28.9339 117.542 29.4722 117.646 29.9906 117.543C30.509 117.44 30.9664 117.138 31.265 116.702C31.411 116.483 31.5124 116.238 31.5636 115.981C31.6148 115.723 31.6148 115.458 31.5635 115.2C31.5122 114.943 31.4107 114.698 31.2647 114.479C31.1188 114.261 30.9312 114.073 30.7128 113.928Z"
          fill="black"
        />
        <path
          d="M52.3478 122.869C51.7043 122.743 51.0644 122.606 50.4283 122.459C49.9119 122.34 49.3697 122.432 48.9207 122.713C48.4717 122.994 48.1525 123.442 48.0333 123.958C47.914 124.474 48.0044 125.017 48.2846 125.466C48.5649 125.916 49.012 126.236 49.5279 126.356C50.2061 126.513 50.8886 126.659 51.5753 126.794C52.0952 126.891 52.6321 126.78 53.0712 126.485C53.5102 126.19 53.8163 125.736 53.924 125.218C54.0264 124.697 53.9179 124.158 53.6223 123.717C53.3267 123.277 52.8683 122.972 52.3478 122.869Z"
          fill="black"
        />
        <path
          d="M41.0898 119.472C40.4829 119.221 39.8808 118.96 39.2836 118.69C39.0442 118.581 38.7857 118.521 38.5229 118.512C38.26 118.503 37.998 118.546 37.7519 118.639C37.5057 118.731 37.2803 118.872 37.0884 119.051C36.8965 119.231 36.742 119.447 36.6336 119.687C36.5253 119.926 36.4653 120.185 36.4571 120.448C36.4489 120.711 36.4926 120.973 36.5857 121.219C36.6788 121.465 36.8195 121.69 36.9998 121.881C37.18 122.073 37.3963 122.227 37.6362 122.335C38.2724 122.623 38.9142 122.901 39.5615 123.168C39.8043 123.269 40.0646 123.321 40.3275 123.321C40.5905 123.321 40.8509 123.27 41.0938 123.169C41.3368 123.069 41.5575 122.921 41.7434 122.735C41.9293 122.549 42.0768 122.329 42.1772 122.086C42.2777 121.843 42.3293 121.582 42.329 121.319C42.3287 121.056 42.2766 120.796 42.1756 120.553C42.0746 120.31 41.9267 120.09 41.7404 119.904C41.5541 119.719 41.333 119.572 41.0898 119.472Z"
          fill="black"
        />
        <path
          d="M63.72 62.12L76.56 83H70.38L60.36 66.68L50.82 83H44.76L57.54 62.12L44.7 41.18H50.82L60.9 57.56L70.5 41.18H76.62L63.72 62.12Z"
          fill="black"
        />
        <path
          d="M82.17 44.89C82.85 44.35 83.16 44.1 83.1 44.14C85.06 42.52 86.6 41.19 87.72 40.15C88.86 39.11 89.82 38.02 90.6 36.88C91.38 35.74 91.77 34.63 91.77 33.55C91.77 32.73 91.58 32.09 91.2 31.63C90.82 31.17 90.25 30.94 89.49 30.94C88.73 30.94 88.13 31.23 87.69 31.81C87.27 32.37 87.06 33.17 87.06 34.21H82.11C82.15 32.51 82.51 31.09 83.19 29.95C83.89 28.81 84.8 27.97 85.92 27.43C87.06 26.89 88.32 26.62 89.7 26.62C92.08 26.62 93.87 27.23 95.07 28.45C96.29 29.67 96.9 31.26 96.9 33.22C96.9 35.36 96.17 37.35 94.71 39.19C93.25 41.01 91.39 42.79 89.13 44.53H97.23V48.7H82.17V44.89Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_66_2">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
