import styled from "styled-components";

export const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const LogoText = styled.h1`
  font-size: 32px;
  color: #072422;

  position: relative;

  &::before {
    content: "";

    background: #f6ae14;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    z-index: -1;

    opacity: 0.7;

    position: absolute;
    top: -2px;
    left: -8px;
  }
`;

export const Slogan = styled.p`
  color: #072422;
  font-weight: 300;
`;
