import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background: -webkit-linear-gradient(to right, #F4F6FF, #EFFFFC);  
    background: linear-gradient(to right, #F4F6FF, #EFFFFC); 
    
    font-family: "Poppins", sans-serif;
  }

  #root {
    padding: 20px 0 0;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  h1,h2,h3 {
    font-family: "Lora", sans-serif;
  }
`;
