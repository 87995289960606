import { useState } from "react";
import Card from "../Card";
import Calculator from "../Calculator";
import Button from "../Button";
import { PercentageIcon } from "../../icons/PercentageIcon";
import { CircleArea } from "../../icons/CircleArea";
import * as S from "./styles";
import { PowIcon } from "../../icons/PowIcon";
import { FatorialIcon } from "../../icons/FatorialIcon";

const Main = ({ calcType, onSelectType }) => {
  const [percentage, setPercentage] = useState("");
  const [radius, setRadius] = useState("");
  const [value, setValue] = useState("");
  const [result, setResult] = useState(null);
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [showSteps, setShowSteps] = useState(false);
  const [base, setBase] = useState("");
  const [pow, setPow] = useState("");
  const [baseFatorial, setBaseFatorial] = useState("");

  const handleFatorialChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }

    setBaseFatorial(value);
  };

  const handleBaseChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }

    setBase(value);
  };

  const handlePowChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }

    setPow(value);
  };

  const handlePercentageChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }

    setPercentage(value);
  };

  const handleValueChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }

    setValue(value);
  };

  const handleRadiusChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }

    setRadius(value);
  };

  const handleCalculate = () => {
    setSteps([]);
    setCurrentStep(0);
    setShowSteps(false);

    if (calcType === "fatorial") {
      const baseValue = parseFloat(baseFatorial);

      if (isNaN(baseValue)) {
        alert("Por favor, insira um valor válido.");
        return;
      }

      let result = 1;
      for (let i = baseValue; i > 0; i--) {
        result *= i;
      }

      setResult(result);
    }

    if (calcType === "potencia") {
      const baseValue = parseFloat(base);
      const powValue = parseFloat(pow);

      if (isNaN(baseValue) || isNaN(powValue)) {
        alert("Por favor, insira valores válidos.");
        return;
      }

      const calculatedResult = Math.pow(baseValue, powValue);
      setResult(calculatedResult);
    }

    if (calcType === "porcentagem") {
      const percentageValue = parseFloat(percentage);
      const baseValue = parseFloat(value);

      if (isNaN(percentageValue) || isNaN(baseValue)) {
        alert("Por favor, insira valores válidos.");
        return;
      }

      const calculatedResult = (percentageValue / 100) * baseValue;
      setResult(calculatedResult);
    }

    if (calcType === "area-do-circulo") {
      const radiusValue = parseFloat(radius);

      if (isNaN(radiusValue)) {
        alert("Por favor, insira um valor de raio válido.");
        return;
      }

      const pi = Math.PI.toFixed(2);

      const calculatedResult = pi * Math.pow(radiusValue, 2);
      setResult(calculatedResult);
    }
  };

  const handleTeachMe = () => {
    setResult(null);

    if (calcType === "porcentagem") {
      const percentageValue = parseFloat(percentage);
      const baseValue = parseFloat(value);

      if (isNaN(percentageValue) || isNaN(baseValue)) {
        alert("Por favor, insira valores válidos.");
        return;
      }

      const percentageStepsArray = [
        {
          description: "Pegue a porcentagem fornecida:",
          action: `${percentageValue}%`,
        },
        {
          description:
            "Converta a porcentagem para um número decimal dividindo por 100:",
          action: `${percentageValue} / 100 = ${percentageValue / 100}`,
        },
        {
          description:
            "Multiplique o valor base pelo valor decimal da porcentagem:",
          action: `${baseValue} * ${percentageValue / 100} = ${
            (percentageValue / 100) * baseValue
          }`,
        },
      ];

      setSteps(percentageStepsArray);
    } else if (calcType === "area-do-circulo") {
      const radiusValue = parseFloat(radius);

      if (isNaN(radiusValue)) {
        alert("Por favor, insira um valor de raio válido.");
        return;
      }

      const circleAreaStepsArray = [
        {
          description: "Pegue o valor do raio fornecido:",
          action: `${radiusValue}`,
        },
        {
          description: "Eleve o valor do raio ao quadrado:",
          action: `${radiusValue}² = ${radiusValue} x ${radiusValue} = ${Math.pow(
            radiusValue,
            2
          )}`,
        },
        {
          description: "Multiplique o resultado por π (pi = 3,14):",
          action: `${Math.pow(radiusValue, 2)} x 3,14 = ${
            Math.PI.toFixed(2) * Math.pow(radiusValue, 2)
          }`,
        },
      ];

      setSteps(circleAreaStepsArray);
    } else if (calcType === "potencia") {
      const baseValue = parseFloat(base);
      const powValue = parseFloat(pow);

      if (isNaN(baseValue) || isNaN(powValue)) {
        alert("Por favor, insira valores válidos.");
        return;
      }

      const powStepsArray = [
        {
          description: "Identificar a base e o expoente:",
          action: `Base = ${baseValue}, Expoente = ${powValue}`,
        },
        {
          description:
            "Multiplique a base por ela mesma repetidamente o número de vezes igual ao valor do expoente:",
          action: `${Array.from({ length: powValue }, () => baseValue).join(
            " x "
          )} = ${Math.pow(baseValue, powValue)}`,
        },
      ];
      setSteps(powStepsArray);
    } else if (calcType === "fatorial") {
      const factorial = (n) => {
        if (n < 0) return undefined; // Fatorial não definido para números negativos
        let result = 1;
        for (let i = 2; i <= n; i++) {
          result *= i;
        }
        return result;
      };

      const fatorialValue = factorial(baseFatorial);

      let actionString;
      if (Number(baseFatorial) === 0) {
        actionString = "0! = 1";
      } else if (Number(baseFatorial) === 1) {
        actionString = "1 × 1 = 1";
      } else {
        actionString = `${Array.from(
          { length: baseFatorial },
          (_, i) => baseFatorial - i
        ).join(" × ")} = ${fatorialValue}`;
      }

      const factorialStepsArray = [
        {
          description: "Identificar a fórmula do fatorial:",
          action: "n! = n × (n-1) × (n-2) × ... × 1",
        },
        {
          description: `Para calcular o fatorial de ${baseFatorial}:`,
          action: actionString,
        },
      ];

      setSteps(factorialStepsArray);
    }

    setCurrentStep(0);
    setShowSteps(true);
  };

  const handleResetButton = () => {
    setPercentage("");
    setValue("");
    setRadius("");
    setResult(null);

    setSteps([]);
    setCurrentStep(0);
    setShowSteps(false);

    onSelectType(null);
  };

  const handleBackButton = () => {
    setResult(null);
    setRadius("");
  };

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      setSteps([]);
      setCurrentStep(0);
      setShowSteps(false);
    }
  };

  return (
    <S.Wrapper>
      {/* Se eu não tenho nenhum calcType selecionado mostro os cards */}
      {!calcType && (
        <>
          <Card>
            <S.CardIcon>
              <PercentageIcon />
            </S.CardIcon>
            <S.CardText>
              <S.CardTitle>Porcentagem</S.CardTitle>
              <S.CardDescription>
                Calcule e aprenda como calcular porcentagem.
              </S.CardDescription>
            </S.CardText>
            {/* Se clicar no botão aprender seta o calType para porcentagem */}
            <Button onClick={() => onSelectType("porcentagem")}>
              Aprender →
            </Button>
          </Card>

          <Card>
            <S.CardIcon>
              <CircleArea />
            </S.CardIcon>
            <S.CardText>
              <S.CardTitle>Área do Círculo</S.CardTitle>
              <S.CardDescription>
                Calcule e aprenda como calcular a área de um círculo.
              </S.CardDescription>
            </S.CardText>
            {/* Se clicar no botão aprender seta o calType para area-do-circulo */}
            <Button onClick={() => onSelectType("area-do-circulo")}>
              Aprender →
            </Button>
          </Card>

          <Card>
            <S.CardIcon>
              <PowIcon />
            </S.CardIcon>
            <S.CardText>
              <S.CardTitle>Potenciação</S.CardTitle>
              <S.CardDescription>
                Calcule e aprenda como calcular a potência.
              </S.CardDescription>
            </S.CardText>
            {/* Se clicar no botão aprender seta o calType para area-do-circulo */}
            <Button onClick={() => onSelectType("potencia")}>Aprender →</Button>
          </Card>

          <Card>
            <S.CardIcon>
              <FatorialIcon />
            </S.CardIcon>
            <S.CardText>
              <S.CardTitle>Fatorial</S.CardTitle>
              <S.CardDescription>
                Calcule e aprenda como calcular fatorial.
              </S.CardDescription>
            </S.CardText>
            {/* Se clicar no botão aprender seta o calType para area-do-circulo */}
            <Button onClick={() => onSelectType("fatorial")}>Aprender →</Button>
          </Card>
        </>
      )}

      {/* Se o calcType for setado como porcentagem mostra esta seção */}
      {calcType && (
        <Calculator>
          <S.CalculatorHeader>
            {result && <Button onClick={handleBackButton}>← Voltar</Button>}
            {steps.length > 0 && (
              <Button onClick={handlePreviousStep}>← Anterior</Button>
            )}
            <S.CalculatorInfo>
              {!result && steps.length === 0 && (
                <>
                  {calcType === "porcentagem" ||
                    (calcType === "potencia" && (
                      <>
                        Insira nos campos abaixo os valores que você deseja
                        calcular:
                      </>
                    ))}
                  {calcType === "area-do-circulo" && (
                    <>
                      Insira no campo abaixo o raio do circulo cuja área você
                      deseja calcular:
                    </>
                  )}
                  {calcType === "fatorial" && (
                    <>
                      Insira no campo abaixo o valor do qual você deseja
                      calcular o fatorial:
                    </>
                  )}
                </>
              )}
              {result && <>RESULTADO</>}
              {steps.length > 0 && <>PASSO {currentStep + 1}</>}
            </S.CalculatorInfo>
            {steps.length > 0 && (
              <Button
                onClick={handleNextStep}
                disabled={currentStep === steps.length - 1}
              >
                Próximo →
              </Button>
            )}
          </S.CalculatorHeader>

          {!result && steps.length === 0 && (
            <S.InputsWrapper>
              {calcType === "porcentagem" && (
                <>
                  <label>
                    Porcentagem (%):
                    <input
                      placeholder="Digite a porcentagem"
                      type="text"
                      value={percentage}
                      onChange={(e) => handlePercentageChange(e)}
                    />
                  </label>

                  <label>
                    Valor:
                    <input
                      placeholder="Digite o valor"
                      type="text"
                      value={value}
                      onChange={(e) => handleValueChange(e)}
                    />
                  </label>
                </>
              )}

              {calcType === "potencia" && (
                <>
                  <label>
                    Base:
                    <input
                      placeholder="Número que você deseja elevar"
                      type="text"
                      value={base}
                      onChange={(e) => handleBaseChange(e)}
                    />
                  </label>

                  <label>
                    Expoente:
                    <input
                      placeholder="Valor do expoente"
                      type="text"
                      value={pow}
                      onChange={(e) => handlePowChange(e)}
                    />
                  </label>
                </>
              )}

              {calcType === "area-do-circulo" && (
                <label>
                  Raio:
                  <input
                    placeholder="Digite o Raio"
                    type="text"
                    value={radius}
                    onChange={(e) => handleRadiusChange(e)}
                  />
                </label>
              )}

              {calcType === "fatorial" && (
                <label>
                  Valor:
                  <input
                    placeholder="Digite o Valor"
                    type="text"
                    value={baseFatorial}
                    onChange={(e) => handleFatorialChange(e)}
                  />
                </label>
              )}
            </S.InputsWrapper>
          )}

          <S.CalculationWrapper>
            {result && (
              <S.ResultText>
                {calcType === "porcentagem" && (
                  <>
                    <strong>{percentage}%</strong> de <strong>{value}</strong> é
                    igual a <strong>{result}</strong>
                  </>
                )}

                {calcType === "potencia" && (
                  <>
                    <strong>{base}</strong> elevado a <strong>{pow}</strong> é
                    igual a <strong>{result}</strong>
                  </>
                )}

                {calcType === "fatorial" && (
                  <>
                    O fatorial de <strong>{baseFatorial}</strong> é igual a{" "}
                    <strong>{result}</strong>
                  </>
                )}

                {calcType === "area-do-circulo" && (
                  <>
                    A área de um circulo com raio <strong>{radius}</strong> é
                    igual a <strong>{result}</strong>
                  </>
                )}
              </S.ResultText>
            )}

            {showSteps && (
              <S.StepWrapper>
                <S.StepDescription>
                  {steps[currentStep].description}
                </S.StepDescription>
                <S.StepAction>{steps[currentStep].action}</S.StepAction>
              </S.StepWrapper>
            )}
          </S.CalculationWrapper>

          <S.ButtonsWrapper>
            <Button onClick={handleCalculate}>Calcular</Button>
            <Button onClick={handleTeachMe}>Me ensine a Calcular</Button>
            <Button onClick={handleResetButton}>VOLTAR AO INÍCIO</Button>
          </S.ButtonsWrapper>
        </Calculator>
      )}
    </S.Wrapper>
  );
};

export default Main;
